export const selectors = {
  actionLink: '.cmp-teaser__action-link',
  image: '.cmp-image',
  imageImg: '.cmp-image__image',
};

export const classes = {
  offsetVariation: 'batcom-teaser-offset',
  stageVariation: 'batcom-teaser-corp-stage',
  verticalVariation: 'batcom-teaser-corp--vertical',
  horizontalVariation: 'batcom-teaser-corp--horizontal',
  isClickable: 'cmp-teaser--clickable',
  curtain: 'batcom-teaser-corp-curtain',
  curtainFixed: 'batcom-teaser-corp-curtain--fixed',
  curtainLimit: 'batcom-teaser-corp-curtain--limit',
  imageHidden: 'cmp-image__image--hidden',
  hasBackground: 'cmp-image--has-background',
}
