import Analytics from './analytics.js';
import { teaser } from './analytics.config.js';

export default class TeaserAnalytics extends Analytics {
  trackActionLinkClick(actionLink) {
    const title = actionLink.innerHTML;
    const event = {
      event: teaser.events.button,
      eventAction: title,
      eventLabel: title,
      url: actionLink.href,
    };
    this.pushAnalytics(event);
  }
}
